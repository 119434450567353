<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <!-- Logged In User Profile Sidebar -->

      <!-- Sidebar Content -->
      <div
        class="sidebar-content"
        :class="{ show: mqShallShowLeftSidebar }"
      >
        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <div class="chat-fixed-search">

          <div class="d-flex align-items-center w-100">
            <div class="sidebar-profile-toggle">
              <b-avatar
                size="42"
                class="cursor-pointer badge-minimal avatar-border-2"
                :src="userData.avatar"
                variant="primary"
                badge
                badge-variant="success"
              >
                <feather-icon
                  v-if="!userData.avatar"
                  icon="UserIcon"
                  size="22"
                />
              </b-avatar>
            </div>
            <!-- Search -->
            <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                :placeholder="`${t('Tìm kiếm')} ...`"
              />

            </b-input-group>
            <b-button
              variant="primary"
              class="btn-icon rounded-circle ml-1"
              @click="$emit('open-new-conversation-modal')"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >
          <!-- Chats Title -->

          <!-- Chats -->
          <ul class="chat-users-list chat-list media-list">

            <ul class="chat-users-list chat-list media-list">
              <conversation-item
                v-for="conversation in conversations"
                :key="conversation.id"
                :conversation="conversation"
                tag="li"
                :class="{ active: activeConversationId === conversation.id }"
                @click="$emit('open-conversation', conversation.id)"
              />
            </ul>

          </ul></vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { ref } from '@vue/composition-api';
import { getUserData } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import ConversationItem from './ConversationItem.vue';

export default {
  components: {
    // BSV
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    // 3rd party
    VuePerfectScrollbar,

    // SFC
    ConversationItem,
  },
  props: {
    conversations: {
      type: Array,
      required: true,
    },
    activeConversationId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    const resolveChatContact = userId => props.contacts.find(contact => contact.id === userId);
    const { t } = useI18nUtils();
    // Search Query
    const searchQuery = ref('');

    const userData = getUserData();
    return {
      // Search Query
      searchQuery,
      t,
      // UI
      resolveChatContact,
      perfectScrollbarSettings,
      userData,
    };
  },
};
</script>
