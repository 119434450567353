<template>
  <div
    class="user-profile-sidebar"
    :class="{ show: shallShowActiveChatContactSidebar }"
  >
    <!-- Header -->
    <header
      v-if="contact"
      class="user-profile-header"
    >
      <span class="close-icon">
        <feather-icon
          icon="XIcon"
          @click="$emit('update:shall-show-active-chat-contact-sidebar', false)"
        />
      </span>

      <div class="header-profile-sidebar">
        <div class="avatar box-shadow-1 avatar-xl avatar-border">
          <b-avatar
            size="70"
            :src="conversation.avatar"
          />

        </div>
        <h4 class="chat-user-name">
          {{ conversation.name }}
        </h4>
      </div>
    </header>

    <!-- User Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="user-profile-sidebar-area scroll-area"
    >
      <!-- About -->
      <h6 class="section-label mb-1">
        {{ t('Thông tin hội thoại') }}
      </h6>
      <p><cite>{{ t('Phân loại') }}:</cite> {{ conversation.bed ? t('Nhóm chat giường') : (conversation.room ? t('Nhóm chat phòng') : t('Nhóm chat tòa nhà')) }} </p>
      <p v-if="conversation.apartment">
        <cite>{{ t('Tòa nhà') }}:</cite> {{ conversation.apartment.name }}
      </p>
      <p v-if="conversation.room">
        <cite>{{ t('Phòng') }}:</cite> {{ conversation.room.name }}
      </p>
      <p v-if="conversation.bed">
        <cite>{{ t('Giường') }}:</cite> {{ conversation.bed.name }}
      </p>

      <!-- Personal Info -->
      <div class="personal-info">
        <h6 class="section-label mb-1 mt-3">
          {{ t('Thành viên nhóm') }}
        </h6>
        <ul class="list-unstyled">
          <li
            v-for="(eachUser, index) in conversation.users"
            :key="index"
            class="mb-1"
          >
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-75"
            />
            <span class="align-middle">{{ eachUser.name }}</span>
          </li>

        </ul>
      </div>

    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { BAvatar } from 'bootstrap-vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    // BSV
    BAvatar,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    shallShowActiveChatContactSidebar: {
      type: Boolean,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
    conversation: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };
    const { t } = useI18nUtils();

    return {
      perfectScrollbarSettings,
      t,
    };
  },
};
</script>

<style></style>
