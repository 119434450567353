import store from "@/store";
import { getCurrentInstance, nextTick, ref } from "@vue/composition-api";
import { useResponsiveAppLeftSidebarVisibility } from "@/@core/comp-functions/ui/app";
import { $themeBreakpoints } from '@themeConfig';
import chatStoreModule from "./chatStoreModule";

export default function useChat() {
  const CHAT_APP_STORE_MODULE_NAME = 'app-chat';

  // Register module
  if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) {
    store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule);
  }

  // Scroll to Bottom ChatLog
  const refChatLogPS = ref(null);
  const scrollToBottomInChatLog = () => {
    const scrollEl = refChatLogPS.value.$el || refChatLogPS.value;
    scrollEl.scrollTop = scrollEl.scrollHeight;
  };

  const vm = getCurrentInstance().proxy;

  // ------------------------------------------------
  // Chats & Contacts
  // ------------------------------------------------
  const conversations = ref([]);

  const fetchConversation = () => {
    store.dispatch('app-chat/getConversations').then(response => {
      conversations.value = response.data.items;
    });
  };

  fetchConversation();

  // ------------------------------------------------
  // Single Chat
  // ------------------------------------------------
  const activeConversation = ref({});
  const chatInputMessage = ref('');

  const openConversation = conversationId => {
    chatInputMessage.value = '';

    store.dispatch('app-chat/getConversationDetail', conversationId).then(response => {
      activeConversation.value = response.data;

      // Scroll to bottom
      nextTick(() => {
        scrollToBottomInChatLog();
      });
    });

    // if SM device =>  Close Chat & Contacts left sidebar
    // eslint-disable-next-line no-use-before-define
    mqShallShowLeftSidebar.value = false;
  };
  const sendMessage = () => {
    if (!chatInputMessage.value) return;
    const payload = {
      conversationId: activeConversation.value.id,
      content: chatInputMessage.value,
    };
    store.dispatch('app-chat/sendMessage', payload).then(() => {
      // Reset send message input value
      chatInputMessage.value = '';

      // Scroll to bottom
      nextTick(() => {
        scrollToBottomInChatLog();
      });
    });
  };

  const perfectScrollbarSettings = {
    maxScrollbarLength: 150,
  };

  // Active Chat Contact Details
  const shallShowActiveChatContactSidebar = ref(false);

  // UI + SM Devices
  // Left Sidebar Responsiveness
  const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
  const startConversation = () => {
    if (store.state.app.windowWidth < $themeBreakpoints.lg) {
      mqShallShowLeftSidebar.value = true;
    }
  };

  const resolveAvatarBadgeVariant = status => {
    if (status === 'online') return 'success';
    if (status === 'busy') return 'danger';
    if (status === 'away') return 'warning';
    return 'secondary';
  };

  const openNewConversationModal = () => {
    vm.$bvModal.show('modal-new-conversation');
  };
  const onNewConversationCreated = newConversation => {
    openConversation(newConversation.id);
    fetchConversation();
  };

  return {

    conversations,
    refChatLogPS,
    activeConversation,
    chatInputMessage,
    shallShowActiveChatContactSidebar,
    perfectScrollbarSettings,
    mqShallShowLeftSidebar,
    openConversation,
    sendMessage,
    resolveAvatarBadgeVariant,
    openNewConversationModal,
    onNewConversationCreated,
    startConversation,

  };
}
