import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChatsAndContacts() {
      return useJwt.getChatsAndContacts().then(response => response);
    },
    getProfileUser() {
      return useJwt.getChatProfile().then(response => response);
    },
    getChat(ctx, { userId }) {
      return useJwt.getChatDetail(userId).then(response => response);
    },
    sendMessage(ctx, data) {
      return useJwt.sendMessage(data);
    },
    getConversations(ctx, params) {
      return useJwt.getConversations(params).then(response => response);
    },
    getConversationDetail(ctx, conversationId) {
      return useJwt.getConversationDetail(conversationId).then(response => response);
    },
    getConversationMessages(ctx, params) {
      return useJwt.getConversationMessages(params).then(response => response);
    },
    createConversation(ctx, data) {
      return useJwt.createConversation(data).then(response => response);
    },
  },
};
