import { ref } from '@vue/composition-api';
import store from '@/store';

import toast from "@/utils/toast";
import chatStoreModule from './chatStoreModule';

export default function useNewConversationModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'app-chat';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, chatStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const blankItem = {
    apartment: null,
    room: null,
    bed: null,
    name: '',
    message: '',
  };
  const itemLocal = ref(blankItem);
  const resetItemLocal = () => {
    itemLocal.value = { ...blankItem };
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      apartmentId: itemLocal.value.apartment ? itemLocal.value.apartment.id : null,
      roomId: itemLocal.value.room ? itemLocal.value.room.id : null,
      bedId: itemLocal.value.bed ? itemLocal.value.bed.id : null,
      name: itemLocal.value.name,
      message: itemLocal.value.message,
    };

    isSubmitting.value = true;
    store
      .dispatch('app-chat/createConversation', data)
      .then(response => {
        emit('on-new-conversation-created', response.data);
        refModal.value.toggle('#toggle-btn');
      })
      .then(() => {
        resetModal();
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
