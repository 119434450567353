<template>
  <b-modal
    id="modal-new-conversation"
    ref="refModal"
    :title="t('Tạo cuộc hội thoại')"
    :ok-title="isSubmitting ? t('Đang tạo') : t('Bắt đầu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"

    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="apartment"
              rules=""
            >
              <select-apartment
                v-model="itemLocal.apartment"
                :filter-active="true"
                :default-value="itemLocal.apartment"
                :label="t('Tòa nhà')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="room"
              rules=""
            >
              <select-room
                v-model="itemLocal.room"
                :default-value="itemLocal.room"
                :label="t('Phòng')"
                :apartment="itemLocal.apartment"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col
            v-if="itemLocal.room && itemLocal.room.numberActiveBeds > 0"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="bed"
              rules=""
            >
              <select-bed
                v-model="itemLocal.bed"
                :room="itemLocal.room"
                :filter-active="true"
                :label="t('Giường')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <!-- Price -->
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t('Tên cuộc hội thoại') }} <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  :placeholder="t('Ngôi nhà vui vẻ')"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="message"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  {{ t('Tin nhắn') }} <span class="text-danger">(*)</span>
                </template>
                <b-form-textarea
                  id="message"
                  v-model="itemLocal.message"
                  :placeholder="t('Xin chào, đây là nhóm chung của tòa nhà, nơi thảo luận và trao đổi các vấn đề chung nhé')"
                  rows="3"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>

          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormTextarea,
  BFormInput,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectBed from '@/views/components/SelectBed.vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import useNewConversationModal from './useNewConversationModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BForm,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormInput,
    SelectBed,
    SelectApartment,
    SelectRoom,
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const { t } = useI18nUtils();
    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useNewConversationModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      t,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
