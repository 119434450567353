<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      size="42"
      :src="conversation.avatar"
      :badge="false"
      class="badge-minimal"
      :badge-variant="resolveAvatarBadgeVariant('online')"
      variant="secondary"
    >
      <feather-icon
        v-if="!conversation.avatar"
        icon="UsersIcon"
        size="22"
      />
    </b-avatar>
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ conversation.name }}
      </h5>
      <p class="card-text text-truncate">
        {{ conversation.lastMessage }}
      </p>
    </div>
    <div
      class="chat-meta text-nowrap"
    >
      <small class="float-right mb-25 chat-time">{{
        formatDateToMonthShort(new Date(), {
          hour: 'numeric',
          minute: 'numeric',
        })
      }}</small>
      <b-badge
        pill
        variant="primary"
        class="float-right"
      >
        3
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue';
import { formatDateToMonthShort } from '@core/utils/filter';
import useChat from './useChat';

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    conversation: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat();
    return { formatDateToMonthShort, resolveAvatarBadgeVariant };
  },
};
</script>

<style></style>
