<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{
        show:
          shallShowActiveChatContactSidebar ||
          mqShallShowLeftSidebar,
      }"
      @click="
        mqShallShowLeftSidebar =
          shallShowActiveChatContactSidebar =
          false
      "
    />

    <!-- Main Area -->
    <section class="chat-app-window">
      <!-- Start Chat Logo -->
      <div
        v-if="!activeConversation || !activeConversation.id"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text font-medium-1 text-center"
          @click="startConversation"
        >
          {{ t('Chọn cuộc hội thoại hoặc liên hệ để bắt đầu') }}
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="activeConversation.avatar"
                class="mr-1 cursor-pointer badge-minimal"
                variant="secondary"
                @click.native="shallShowActiveChatContactSidebar = true"
              >
                <feather-icon
                  v-if="!activeConversation.avatar"
                  icon="UsersIcon"
                  size="22"
                />
              </b-avatar>
              <h6 class="mb-0">
                {{ activeConversation.name }}
              </h6>
            </div>

            <!-- Contact Actions -->
            <div class="d-flex align-items-center">
              <b-button
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                @click="shallShowActiveChatContactSidebar = true"
              >
                <feather-icon
                  icon="SettingsIcon"
                  size="17"
                  class="cursor-pointer d-sm-block d-none"
                />
              </b-button>
              <!-- <feather-icon
                icon="SettingsIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
              /> -->
              <!-- <div class="dropdown">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item> View Contact </b-dropdown-item>
                  <b-dropdown-item> Mute Notifications </b-dropdown-item>
                  <b-dropdown-item> Block Contact </b-dropdown-item>
                  <b-dropdown-item> Clear Chat </b-dropdown-item>
                  <b-dropdown-item> Report </b-dropdown-item>
                </b-dropdown>
              </div> -->
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :conversation="activeConversation"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Nhập tin nhắn"
            />
          </b-input-group>
          <b-button
            variant="primary"
            type="submit"
          >
            {{ t('Gửi') }}
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="
        shallShowActiveChatContactSidebar
      "
      :contact="{}"
      :conversation="activeConversation"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :conversations="conversations"
        :active-conversation-id="activeConversation ? activeConversation.id : null"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @open-conversation="openConversation"
        @open-new-conversation-modal="openNewConversationModal"
      />
    </portal>

    <new-conversation-modal @on-new-conversation-created="onNewConversationCreated" />
  </div>
</template>

<script>

import {
  BAvatar,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import ChatLeftSidebar from './ChatLeftSidebar.vue';
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue';
import ChatLog from './ChatLog.vue';
import useChat from './useChat';
import NewConversationModal from './NewConversationModal.vue';

export default {
  components: {
    // BSV
    BAvatar,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
    NewConversationModal,
  },
  setup() {
    const {
      conversations,
      refChatLogPS,
      activeConversation,
      chatInputMessage,
      shallShowActiveChatContactSidebar,
      perfectScrollbarSettings,
      mqShallShowLeftSidebar,
      openConversation,
      sendMessage,
      resolveAvatarBadgeVariant,
      openNewConversationModal,
      onNewConversationCreated,
      startConversation,
    } = useChat();

    const { t } = useI18nUtils();

    return {
      conversations,
      refChatLogPS,
      activeConversation,
      chatInputMessage,
      shallShowActiveChatContactSidebar,
      perfectScrollbarSettings,
      mqShallShowLeftSidebar,
      openConversation,
      sendMessage,
      resolveAvatarBadgeVariant,
      openNewConversationModal,
      onNewConversationCreated,
      startConversation,
      t,
    };
  },

};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import '~@core/scss/base/pages/app-chat.scss';
@import '~@core/scss/base/pages/app-chat-list.scss';
</style>
